<template>
    <SimpleTable formclass="pages/User/form" endpoint="api/v2/user" hideDelete inlineToggle :aditionalColumns="headers">
        <template v-slot="data">
            <h1>Usuários</h1>
            <v-divider class="mb-4" />
            <v-layout wrap class="my-12">
                <v-flex xs12 sm6>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                {{ total(data.items) }}
                            </div>
                            Cadastrados
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm6>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                {{ active(data.items) }}
                            </div>
                            Ativos
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </template>
    </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";
export default {
    components: { SimpleTable },
    data() {
        return {
            headers: [
                { text: this.$t("Habilitado"), value: "enable", align: "right", sortable: false },
                { text: "Último acesso", value: "lastAccess", align: "right", sortable: true },
                { text: "Número de acessos", value: "counter", align: "right", sortable: true },
                { text: "Email", value: "email", align: "right", sortable: true },
                { text: "Nome", value: "name", align: "right", sortable: true },
            ],
        };
    },

    methods: {
        total(items) {
            return Number.parseFloat(items.length).toFixed(0);
        },
        active(items) {
            return Number.parseFloat(items.reduce((sum, i) => (i.enable ? sum + 1 : sum), 0)).toFixed(0);
        },
    },
};
</script>